import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { io } from 'socket.io-client';

const socket = io('http://109.73.111.169:3030')

Vue.use(VueVideoPlayer)
Vue.use(Buefy)

Vue.config.productionTip = false
Vue.prototype.$socket = socket

new Vue({
  render: h => h(App),
}).$mount('#app')
