<template>
  <div class="hello">
      <div>
        <b-button
          type="is-success"
          class="button"
          @click="switchPanel(true)"
        >Join room</b-button>
        <b-button
          type="is-info"
          class="button button--red"
          @click="switchPanel(false)"
        >Create room</b-button>
      </div>
      <div>
        <div v-if="joinRoom" >
          <input class="roomInput" v-model="roomName" placeholder="ENTER EXISTING ROOM NAME"/>
          <b-button
            type="is-success"
            class="button"
            @click="attemptToJoinRoom"
          >Join existing room</b-button>
        </div>
        <div v-else>
          <input class="roomInput" v-model="roomName" placeholder="CREATE NEW ROOM ENTER NAME"/>
          <input class="roomInput" v-model="roomUrl" placeholder="URL TO .MP4 FILE"/>
          <b-button
            type="is-danger"
            class="button"
            @click="attemptToJoinRoom"
          >Create new room as room admin</b-button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  data() {
    return {
      joinRoom: true,
      roomName: '',
      roomUrl: ''
    }
  },
  created() {
    this.$socket.on('roomJoined', (roomData)=>{
        console.log('joined room')
        this.$emit('changeToPlayer', roomData);
    })
  },
  methods: {
    switchPanel: function(val) {
      this.joinRoom = val
    },
    attemptToJoinRoom: function() {
      if(this.joinRoom) {
        console.log(this.roomName)
        this.$socket.emit('joinRoom', {
          roomName: this.roomName,
        })
      } else {
        this.$socket.emit('createRoom', {
          roomName: this.roomName,
          roomUrl: this.roomUrl
        })
      }
    }
  }
}
</script>

<style scoped>
.button {
  margin: 5px;
  color: #dadada;
}

.button--red {
  background-color: #850101;
}

.roomInput {
  display: block;
  border-radius: 11px;
  height: 40px;
  width: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
  overflow: hidden;
  border: 2px solid #a4a4a4;
  background-color: #2b3742;
  color: #fef2ec;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

input:focus {
  outline: none;
}
</style>
