<template>
  <div>
    <video-player  class="video-player-box"
                  ref="videoPlayer"
                  :options="playerOptions"
                  :playsinline="true"
                  customEventName="customstatechangedeventname"
                  @play="onPlayerPlay"
                  @pause="onPlayerPause"
                  @timeupdate="onTimeUpdate"
    >
    </video-player>
  </div>
</template>
 
<script>
  export default {
    name: 'MovieRoom',
    props: ['roomName', 'isAdmin', 'roomUrl'],
    data() {
      return {
        playerOptions: {
          muted: true,
          language: 'en',
          sources: [{
            type: "video/mp4",
            src: this.roomUrl
          }],
        },

      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)
      if(!this.isAdmin) {
        this.player.controlBar.progressControl.dispose()
        this.player.controlBar.playToggle.dispose()
      }

      this.$socket.emit('checkIfVideoIsPlaying', this.roomName)

      this.$socket.on('updatedVideoTimeStamp', function(videoTimeStamp) {
        const timeDiff = videoTimeStamp - this.player.currentTime()
        if(timeDiff < -2 || timeDiff > 2) {
          console.log('Video falling behind for more than 3 sec updating video catching up...')
          this.player.currentTime(videoTimeStamp)
        }
      }.bind(this))

      this.$socket.on('videoPlaying', function(isVideoPlaying) {
        if(isVideoPlaying) {
          this.player.play()
        } else {
          this.player.pause()
        }
      }.bind(this))
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      },
    },
    methods: {
        onPlayerPlay: function () {
          this.$socket.emit('videoPlayerPlay', this.roomName)
        },
        onPlayerPause: function () {
          this.$socket.emit('videoPlayerPause', this.roomName)
        },
        onTimeUpdate: function() {
          this.$socket.emit('updateVideoTimeStamp', {
            videoTimeStamp: this.player.currentTime(),
            roomName: this.roomName
          })
        }

    }
  }
</script>