<template>
  <div class="app">
    <div class="wrapper">
      <MovieRoom 
        v-if="roomName" 
        :room-name="roomName"
        :is-admin="isAdmin"
        :room-url="roomUrl"
      />
      <MainMenu v-else @changeToPlayer="changeToPlayer"/>
    </div>
  </div>
</template>

<script>
import MainMenu from './components/MainMenu.vue';
import MovieRoom from './components/MovieRoom.vue';

export default {
  name: 'App',
  components: {
    MainMenu,
    MovieRoom
  },
  data: function () {
    return {
      roomName: undefined,
      isAdmin: false,
      roomUrl: undefined
    }
  },
  methods: {
    changeToPlayer: function(roomData) {
      this.roomName = roomData.roomName
      this.isAdmin = roomData.isAdmin
      this.roomUrl = roomData.roomUrl
    }
  },
  created() {
    this.$socket.on('error', (message)=>{
        this.$buefy.notification.open({
                duration: 3000,
                message,
                position: 'is-top-left',
                type: 'is-danger',
                hasIcon: false
        })
    })
  }
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #040c13;
}
.wrapper {

}
</style>
